.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    height: 80vh;
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, "system-ui", Roboto, "Helvetica Neue", sans-serif !important;
    .custom-card {
        border: 1px solid #E0E0E0;
        border-radius: 16px;
        background-color: #fff;
    }
    .login-container {
        display: flex;
        justify-content: space-between;
        margin-left: 30px;
        margin-right: 80px;
        margin-top: 40px;
        margin-bottom: 40px;
        justify-content: center;
        background-color: #fff;
        .login-functionality {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 60px;
            h1 {
                font-size: 28px;
                font-weight: 600;
            }

            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #616161;
                margin-top: 15px;
            }

            .btn {
                cursor: pointer;
                -webkit-box-align: center;
                align-items: center;
                box-sizing: border-box;
                display: inline-flex;
                -webkit-box-pack: center;
                justify-content: center;
                text-decoration-line: none;
                vertical-align: middle;
                margin: 0px;
                overflow: hidden;
                border: 1px solid #d1d1d1;
                font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
                outline-style: none;
                padding: 5px 12px;
                min-width: 96px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                transition-duration: 100ms;
                transition-property: background, border, color;
                transition-timing-function: cubic-bezier(0.33,0,0.67,1);
                background-color: #1A2AA4;
                border-top-color: transparent;
                border-right-color: transparent;
                border-left-color: transparent;
                border-bottom-color: transparent;
                color: #fff;
                margin-top: 25px;

                &:hover {
                    background-color: #16248C;
                }

                &:active {
                    background-color: #0E1656;
                }
            }
        }
    }
}