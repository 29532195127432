.file-upload-wrapper {
    max-width: 600px;
    width: 100%;

    .file-upload-subtitle {
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 600;
    }

    .file-upload-subitem-wrapper {
        padding: 20px;
    }
}