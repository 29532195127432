.data-access-policies-table-container {

    tr,
    th {
        border-bottom: 3px solid #D9DBE9 !important;
    }

    .inner-dap-policy {
        border-bottom: 1px solid #D9DBE9 !important;
    }

    .MuiTableCell-root {
        font-size: 13px!important;
    }
}