.link-default {
    font-family: Lato, sans-serif;
    font-size: 14px;
    cursor: pointer;
}

.MuiLink-root.MuiLink-underlineAlways {
    cursor: pointer!important;
}

.MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(270deg, #2A3EF3 0%, #7551D9 100%) !important;
}

.btn-default-outlined {
    border: 2px solid #2A3EF3 !important;
    color: #2A3EF3 !important;
    font-weight: 700 !important;
    text-transform: none !important;
    border-radius: 8px !important;
    font-family: 'Lato', sans-serif !important;

    &:hover {
        background-color: rgba(42, 62, 243, 0.1) !important;
    }

    &:disabled {
        color: #A0A9F6 !important;
        border: 2px solid #A0A9F6 !important;
    }

    &:active {
        box-shadow: none !important;
        background-color: rgba(42, 62, 243, 0.1) !important;
        border-color: #005cbf !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}


.btn-default {
    background: linear-gradient(270deg, #2A3EF3 0%, #7551D9 100%) !important;
    border-radius: 8px !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;

    &:hover {
        background: linear-gradient(270deg, #2133D0 0%, #6143B8 100%) !important;
        ;
    }

    &:disabled {
        background: #A0A9F6 !important;
    }

    & .MuiLoadingButton-loadingIndicator {
        color: #fff !important;
    }

    &.MuiLoadingButton-loading {
        color: transparent !important;
    }
}

.card {
    border-radius: 16px !important;

    &.with-border {
        border: 1px solid #DCDDE3 !important;
    }

    &.without-border {
        border: none !important;
        background: rgba(230, 227, 255, 0.2) !important;
    }
}

.input {
    background-color: #fff !important;
    border-radius: 6px !important;

    & input {
        font-family: 'Lato', sans-serif !important;
    }

    & .MuiInputLabel-root {
        font-family: 'Lato', sans-serif !important;
        color: #6E7191 !important;

        &.Mui-error {
            &.MuiFormLabel-root {
                color: #D01010 !important;
            }
        }
    }

    &.small {
        height: 40px;
        font-size: 8px;

        & input {
            font-size: 13px;
        }

        & .MuiInputLabel-root {
            font-size: 13px;
            margin-top: -2px;
        }
    }

    &.small-autocomplete {
        height: 40px;
        font-size: 8px;

        & input {
            font-size: 13px;
            margin-top: -2px;
        }

        & .MuiInputLabel-root {
            font-size: 13px;
            margin-top: -2px;
        }

        & .MuiFilledInput-input {
            padding: 0!important;
        }

        .MuiInputBase-root {
            height: 40px!important;
        }
    }

    & .MuiOutlinedInput-root {
        border: 0 !important;
    }

    & .MuiFilledInput-root {
        font-family: 'Lato', sans-serif !important;
        border: 2px solid #DCDDE3 !important;
        overflow: hidden !important;
        border-radius: 6px !important;
        background-color: #fff !important;
        color: #222222 !important;

        &:hover {
            border: 2px solid #14142B !important;
        }

        &.Mui-focused {
            border: 2px solid #14142B !important;
        }

        &.Mui-disabled {
            border: 2px solid #EFF0F7 !important;
            background: #EFF0F7 !important;
        }

        &.Mui-error {
            background: rgba(235, 42, 42, 0.05) !important;
            border: 2px solid #EB2A2A !important;
        }
    }
}

.MuiAutocomplete-popper {
    margin-top: 10px !important;

    & .MuiPaper-root {
        box-shadow: 0px 5px 20px rgba(7, 11, 47, 0.3) !important;
    }

    & ul {
        font-family: 'Lato', sans-serif !important;
    }

    & li {
        font-size: 13px !important;
        &:hover {
            color: #2A3EF3 !important;
            background-color: #fff !important;
        }

        &:active {
            color: #2A3EF3 !important;
            background-color: #fff !important;
        }
    }
}


.radio {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #A0A3BD;
    cursor: pointer;

    &.checked {
        width: 9px;
        height: 9px;
        background-color: #fff;
        border: 6px solid #2A3EF3;

        &:hover {
            width: 9px;
            height: 9px;
            background-color: #fff;
            border: 6px solid #2A3EF3;
        }
    }

    &:hover {
        background: #A0A3BD;
    }
}

.radio-container {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
}

.back-construction {

    display: flex;
    align-items: center;

    h2 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 24px;
    }

    .icon-back {
        font-size: 30px;
        color: #2A3EF3;
        cursor: pointer;
    }
}

tr,
th,
td {
    font-family: 'Lato', sans-serif !important;
}

th {
    color: #6E7191 !important;
}

tr,
th {
    border-bottom: 1px solid #D9DBE9 !important;
}

.lato {
    font-family: 'Lato', sans-serif !important;
}

.center {
    text-align: center;
}

.bold {
    font-weight: 600 !important;
}

.input-helper-text-error {
    font-family: 'Lato', sans-serif !important;
    font-size: 12px;
    min-height: 12px;
    color: #D01010;
    margin-top: 5px;
}

p {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}