.log-history-filtering-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    .MuiAutocomplete-loading {
        display: flex;
        justify-content: center;
    }
}