.header {
    
    img {
        height: 45px;
    }
    .logo-block {
        display: flex;
        align-items: center;
        h3 {
            font-size: 18px;
            color: #000;
            font-weight: 600;
            font-family: 'Lato',sans-serif;
        }
    }
}


.user-info {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 40px;
    p {
        font-family: 'Lato', sans-serif;
        margin-left: 5px;
    }
}

.user-round {
    width: 30px;
    height: 30px;
    background-color: #2A3EF3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
}